* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100%;
    padding: 5% 0%;
    font-family: "Roboto","Helvetica Neue","Helvetica","Arial",sans-serif;
    background-color: white;

}

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .card {
        border-radius: 5px;
        padding: 2% 3%;
        width: 70%;
        display: flex;
        flex-direction: column;
        margin-bottom: 5.5%;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.331) 0px 0px 4px;


        h1 {
            text-align: center;
            font-size: 3vw;
        }

        p {
            text-align: center;
            // font-size: 1.5vw;
            font-size: 1.125rem;

            color: #41464a;
            text-align: start;
            line-height: 1.45;

        }

        img {
            width: 70%;
            margin: 8% 0%;
        }


        .card-details {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 30px;
            h5 {
                font-weight: 300;
            }
        }

        @media screen and (max-width: 768px) {
            width: 95%;
            margin: 7% 0%;
            h1 {
                font-weight:400;
                padding: 0;
                width: 100%;
                font-size: 5vw;
                width: 100%;
            }
            p, h4 {
                padding: 2%;
                font-weight: 350;
            }
        }
    }
}